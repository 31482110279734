import './Social.css';

function Social(props) {
  return (
    <a className="social" id={props.text} target='_blank' rel='noreferrer' href={props.link}>
      <img className='social__ico' src={props.picture} alt={props.text}/>
    </a>
  );
}

export default Social;