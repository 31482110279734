import React from "react";
import tomato from "../images/tomato.png";
import tomato1 from "../images/tomato1.png";
import tomato2 from "../images/tomato2.png";
import writober from "../images/writober.png";
import writober1 from "../images/writober1.png";
import writober2 from "../images/writober2.png";
import writober3 from "../images/writober3.png";
import nystory from "../images/nystory.png";
import nystory1 from "../images/nystory1.png";
import mills from "../images/mills.png";
import mills1 from "../images/mills1.png";
import mills2 from "../images/mills2.png";
import mills3 from "../images/mills3.png";
import place from "../images/place.png";
import place1 from "../images/place1.png";
import place2 from "../images/place2.png";
import movies from "../images/movies.png";
import movies1 from "../images/movies1.png";
import movies2 from "../images/movies2.png";
import movies3 from "../images/movies3.png";
import movies4 from "../images/movies4.png";
import learn from "../images/learn.png";
import learn1 from "../images/learn1.png";
import travel from "../images/travel.png";
import travel1 from "../images/travel1.png";
import travel2 from "../images/travel2.png";

export const LanguageContext = React.createContext();
export const languages = {
  en: {
    header: {
      title: "It's me, Julia!",
        info: [
          {
            caption: "",
            text: [
              "Hi there! My name is Julia and I'm so happy to meet you. ",
//1              "As a web developer with an artistic mind, I understand the importance of finding someone who truly understands your vision and can bring it to life.",
//              "As a web developer with a PhD and an artistic mind, I understand the importance of finding someone who truly understands your vision and can bring it to life.",
//2              "I'm here to listen to your ideas, understand your needs, and work with you to create a website that truly reflects your unique style and goals. Let's work together to make your vision a reality!"
            ],
            link: "",
            alterlink: ""
          },
         {
            caption: "",
            text: "As a web developer with an artistic mind, I understand the importance of finding someone who truly understands your vision and can bring it to life.",
            link: "",
            alterlink: ""
          },
         {
            caption: "",
            text: "I'm here to listen to your ideas, understand your needs, and work with you to create a website that truly reflects your unique style and goals. Let's work together to make your vision a reality!",
            link: "",
            alterlink: ""
          }
        ]
    },
    footer: {
      caption: "",
      text: "©2023 Julia Torshenko",
      link: "https://www.linkedin.com/in/torshenko/",
      alterlink: "Dare to visit my LinkedIn!"
    },
    service: {
      yes: "Yes",
      no: "No",
      sure: "Are you sure?",
      titleoff: "Show",
      techs: "",
      card: {
        git: "Progect's GitHub repository",
        techs: "Technologies:",
      }
    },
    fancy: {
      game: {
        titleon: "The Rainbow Blocks Game",
        titleoff: "Play the Game!",
        intro: "Each time you click the block it and its same-colored neighbours change their color. In RGB-cycle: ",
        span: [
          "Red",
          "Yellow",
          "Green",
          "Cyan",
          "Blue",
          "Magenta"
        ],
        myscore: "My score",
        yourscore: "Your score",
        topscore: "Top score",
        mvs: "mvs",
        pts: "pts",
        gameover: "Game over!",
        again: "Play again?",
        restart: "ReSTART",
        refresh: "ReFRESH"
      }
    },
    projects: [
          {
            caption: "Writober",
            pictures: [writober2, writober, writober1, writober3],
            text: "Creative generator for writers and poets. Made to perform a Writober-23 activity in our comunity.",
            long: [
              "1. App generates topics for writing.",
              "2. You may choose base and difficulty.",
              '3. Every click on "generate" button creates a card with one from 150+ random topics and 200+ options. Options make card almost unique (several millions variations), so you can generate as long as you wish to choose a variation you like.',
              "4. You can discard cards you don't like or reset all the stack.",
              "Idea, development and by me, texts by both my friend Marianna and me."
            ],
 //           gitlink: "https://github.com/ulkerei/my-writober",
            mainlink: "https://ulkerei.github.io/my-writober/",
            link: "https://vk.com/plaguestories",
            alterlink: "Our community in russian socials",
            techs: ["HTML", "CSS","JS","React"]
          },
          {
            caption: "Popcorn movies",
            pictures: [movies, movies2, movies3, movies1, movies4],
            text: "A study dimloma-project: movie library.",
            long: [
              "Functionality:",
              "Registration and authorization.",
              "Logged users able to change their personal information, search movies, save them in own library, delete from it.",
              "Non-authorized user not able to reach secure routes.",
              "App works with two APIs. Own backend part - to save and delete movies, manage users; and external - to search movies in DB.",
            ],
            gitlink: "https://github.com/ulkerei/movies-explorer-frontend",
            mainlink: "https://www.loom.com/share/6f809d04d7f44028aa5b2e75cd30ed54",
            link:"https://github.com/ulkerei/movies-explorer-frontend",
            alterlink: "Backend's git repository",
            techs: ["HTML", "CSS","JS","React","Node.js","MongoDb","Express","Nginx"]
          },
          {
            caption: "Weather mills",
            pictures: [mills3, mills, mills1, mills2],
            text: "Wind is blowing, wings are spinning reacting to outer conditions. Just a demo.",
            long: "Now I'm working on linking this progect to real weather.",
            gitlink: "",
            mainlink: "https://www.torshenko.eu/mills/",
            alterlink: "",
            techs: ["HTML", "CSS","JS","React"]
          },
          {
            caption: "Mesto (the Place)",
            pictures: [place, place2, place1],
            text: "A study project: social app where users could share theirs photos.",
            long: ["Functionality:", "Registration and authorization.","Logged users able to change their personal information, add pictures, like/unlike pictures, delete own pictures.","Non-authorized user not able to reach secure routes.","My study grant from Ya.cloud, a grant from Ya.cloud is over, so by clicking a link you'll get lighter version of the project. You can run it locally or just watch a demonstration below."],
            gitlink: "https://github.com/ulkerei/react-mesto-api-full",
            mainlink: "https://ulkerei.github.io/mesto-react/",
            link: "https://www.loom.com/embed/9a78fa9f570c4faabd971421017bf013",
            alterlink: "Watch my loom-video",
            techs: ["HTML", "CSS","JS","React","Node.js","MongoDb","Express"]
          },
          {
            caption: "Random story bag",
            pictures: [nystory, nystory1],
            text: "Creative generator for writers and poets. Made to perform a New Year (2023) contest in our comunity.",
            long: [
              "App generates topics for writing.",
              "You may choose to use only common topics or add Christmas and NY-themed ones.",
              'Every click on "generate" button creates a card with one from 30 random topics and several options. Options make card almost unique (several millions variations), so you can generate as long as you wish to choose a variation you like.',
              "You can discard cards you don't like or reset all the stack."],
            gitlink: "https://github.com/ulkerei/random-story-bag",
            mainlink: "https://ulkerei.github.io/random-story-bag/",
            techs: ["HTML", "CSS","JS","React"]
          },
         {
            caption: "Pomidorro!",
            pictures: [tomato, tomato1, tomato2],
            text: "Crazy site about tomatoes",
            long: 'Made only in purpose stylization HTML-elements only by means of css. All the textes make not much sense, kinda "Lorem Ipsum" but about tomatoes. I used a crazy 80-90th color schema, it is little weird but very nostalgic.',
            gitlink: "https://github.com/ulkerei/tomato",
            mainlink: "https://ulkerei.github.io/tomato/index.html",
            techs: ["HTML", "CSS"]
          },
          {
            caption: "Russian travel",
            pictures: [travel, travel1, travel2],
            text: "An adaptive website",
            long: ["Training project in Yandex Workshop on web development - an adaptive website about traveling around Russia."],
            gitlink: "https://github.com/ulkerei/russian-travel",
            mainlink: "https://ulkerei.github.io/russian-travel/index.html",
            alterlink: "",
            techs: ["HTML", "CSS"]
          },
          {
            caption: "How to learn",
            pictures: [learn, learn1],
            text: "Study project",
            long: ["The training project of the second sprint Yandex-Practicum on web development is a one-page website about training techniques.","Functionality: Demonstrates the use of various HTML5 design effects."],
            gitlink: "https://github.com/ulkerei/how-to-learn",
            mainlink: "https://ulkerei.github.io/how-to-learn/",
            alterlink: "",
            techs: ["HTML", "CSS"]
          },
    ],
    sections: [
      {
        id: "about",
        name: "About",
        hide: true,
        info: [
          {
//            caption: "Welcome to My Digital Realm:",
            text: [
              "My coding style for web development has four key features. I prioritize adaptivity, incorporate eye-catching design, infuse projects with dynamic elements, and create reusable components. This blend results in a functional and visually engaging digital experience. I also have full-stack skills that allow me to create interactive and personalized websites. Ready to elevate your project with dynamic functionality and responsive design?"
            ],
            link: "",
            alterlink: ""
          },

          {
//            caption: "My Distinctive Style of Coding",
            text: [
              "I specialize in adding interactive elements to projects to improve user engagement and create a better overall experience. My focus is on designing reusable components that can be used throughout the development process, making it more efficient and consistent."
            ],
            link: "",
            alterlink: ""
          },
          {
            caption: "Technologies that I use:",
            text: ["HTML", "CSS","JS","React","Figma","Node.js","MongoDb","Express","Nginx"],
            techs: [],
            link: "",
            alterlink: ""
          },
          {
            caption: "GAME TIME!",
            text: [
              "I know how boring could be browsing dev's portfolios. Have fun! Challenge your mind with a logical browser game. Because web development can be fun too!"
            ],
            link: "",
            alterlink: ""
          },
          {
            caption: "Whant to know more?",
            text: [
              "Discover my education and experiences — the story behind the code. Ready to collaborate or just chat? Click my socials, and let's bring your ideas to life!"
            ],
            link: "",
            alterlink: ""
          }
        ]
      },
            {
        id: "game",
        name: "Play the GAME",
        hide: false,
        info: [
          {
            caption: "",
            text: [
              "You goal is to keep the board as multicolored as you can till the 120th action (the game stops earlier if you paint all the blocks with the same color). Beware: repainting large areas especially on first turns dicreases your score. It could fall even below zero.",
              "My top-score was 7058 pts at 120th move. Try to beat me ;)"
            ],
            link: "",
            alterlink: ""
          }
        ]
      },
      {
        id: "experience",
        name: "Experience",
        hide: true,
        info: [
          {
            caption: "My Unconventional Journey:",
            text: [
              "Picture this: a computer science master and a qualified higher education lecturer. Throw in a PhD for good measure. For years, I immersed myself in the academic world, navigating the intricate halls of university life.",
              "But here's the twist — I got tired. Exhausted, to be brutally honest. And at the same moment I had to move to another county. Maybe it was kind of a coincidence, maybe a fresh start just gave me an opportunity to think about my way... But finally, I realized I had grown weary of the academic grind.",
              "In my university days, I dabbled in front-end side projects. I wasn't a complete rookie, but I craved more. Last year, I took a bold step, deciding to leave education behind and dive headfirst into the dynamic world of web development. I enrolled in a year-long course, not just to brush up on my skills but to embrace new technologies, ensuring I'd be not just effective but exceptional in this field. It's been a journey of reinvention, and I'm here to show that a PhD and a love for coding can coexist.",
              "Ready to bring this unique blend of academia and frontend prowess to your next project."
            ],
            link: "",
            alterlink: ""
          },
          {
            caption: "Freelance Web Developer",
            text: [
              "2022 - now",
              " I am a detail-oriented problem solver who is always up for a challenge. I am dedicated to delivering high-quality code and ensuring that the websites I create are not just functional but also user-friendly. My goal is to make the internet a better place, one website at a time."
            ],
            link: "",
            alterlink: ""
          },
                    {
            caption: "Saint Petersburg State University of Telecommunications",
            text: [
              "2010 - 2022, Associate Professor",
              "Oversaw bachelor's and master's diploma projects in information security and management. Delivered diverse courses bridging IT, economics, and management. Developed courses on 'Introduction to NBIC Technologies' and introduced the 'Social Network Targeting' elective program."
            ],
            link: "",
            alterlink: ""
          },
                    {
            caption: "ITMO University",
            text: [
              "2007 - 2020, Associate Professor",
              "Guided bachelor's and master's diploma projects in information security and management. Taught courses at the nexus of IT, economics, and management, with a focus on risk analysis. Organized several local and international IT Security conferences. Pioneered the creation of a 'Economical Information Security' program and conducted information system audits for enterprise organizations."
            ],
            link: "",
            alterlink: ""
          }
        ]
      },
      {
        id: "portfolio",
        name: "Portfolio",
        hide: false,
        info: [
          {
            caption: "",
            text: "Here are some my pet and study project.",
            link: "https://github.com/ulkerei",
            alterlink: "Take a close look at my Github page"
          }
        ]
      },
      {
        id: "education",
        name: "Education",
        hide: true,
        info: [
          {
            caption: "Web development",
            text: [
              "2022 -2023, Yandex Practicum",
              "I've been studying web development, which includes learning the following key areas: HTML, CSS, JS, React, MongoDB, NodeJS, etc.",
              "In combination, these skills enable me to develop full-stack web applications, from creating the user interface to managing the server-side functionality."
            ],
            link: "",
            alterlink: ""
          },
          {
            caption: "Doctor of Philosophy - PhD, Computer and Information Systems Security",
            text: [
              "2007 - 2009, ITMO University"
            ],
            link: "",
            alterlink: ""
          },
          {
            caption: "Engineer's degree, Higher Education/Higher Education Administration",
            text: [
              "2007 - 2009, ITMO University",
              "I have acquired fundamental knowledge of computer hardware and software security, web application security, including network protocols, and operating systems architecture. My graduation project was centered on the application of ISO27001 standards in a local context, with a specific emphasis on integrating its principles into the development of web applications."
            ],
            link: "",
            alterlink: ""
          },
          {
            caption: "Engineer's degree, Computer and Information Systems Security/Information Assurance",
            text: [
              "2001 - 2007, ITMO University"
            ],
            link: "",
            alterlink: ""
          }
        ]
      },
      {
        id: "more",
        name: "More about me",
        hide: true,
        info: [
          {
            caption: "",
            text: [
              "I am a creative individual passionate about various forms of art—writing short stories, novels, and poems, painting, photography, and crafting, including designing costumes and stage decorations. Exploring country landscapes, biking, and visiting medieval castles serve as constant inspirations for my creative pursuits.",
              "After completing my computer science master's degree, earning a PhD, and teaching at the university, I experienced burnout and sought change. Moving to a new country marked a turning point, leading me to bid farewell to academia and embark on a new journey in web development.",
              "Notably, during this transformative year, I navigated parenting, language learning, and cultural adaptation, even completing a fiction novel. Beyond coding skills, I bring a wealth of life experiences to your project. Let's infuse this diverse mix into your next endeavor!"
            ],
            link: "",
            alterlink: ""
          }
        ]
      }
    ]
  },
  ru: {

  }
}