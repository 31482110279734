import './Background.css';
import React from 'react';

function Background(props) {
  function getDirection(i) {
    let styleSheet = null;
    if(!styleSheet) {
      styleSheet = document.createElement('style'); 
      styleSheet.type = 'text/css';
      document.head.appendChild(styleSheet);
    }
      styleSheet.sheet.insertRule(`@keyframes move${i} {
        100% {
          top:  ${Math.random()*120 - 20}%;
          left: ${Math.random()*120 - 10}%;
        }
      }`,styleSheet.length); 
  }

  function getPosition() {
    let initX = Math.random()*100;
    if (props.main && (Math.floor(initX) % 2 !== 0)) {
      if (20 < initX < 80) {
        (initX < 50) ? initX = Math.random()*20 - 5: initX = 105 - Math.random()*20;
      }
    }
    return initX;
  }

  function floating (counter=12){
    const drops = [];
    for (let i=0; i < counter; i++) {
      getDirection(i);
      const shape = Math.floor((Math.random() * 3));
      drops.push(
      <div className='background__container' key={i} style={{animation: `move${i} ease alternate infinite` ,animationDuration: `${Math.random() * 10 + 20}s`, animationDelay: `${Math.random()*5}s`,left: `${getPosition()}%`, top: `calc(${Math.random()*100 - 4}% - 80px)`}}> 
        <div className={`background__shape background__shape_${shape}`} style={{animationDuration: `${Math.random() * 10 + 5}s`, animationDelay: `${Math.random()*3}s`}} />
      </div>);
    }
    return drops;
  }

  return (
    <div className="background">
      {floating(props.main)}
    </div>
  );
}

export default Background;