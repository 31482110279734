import './Game.css';
import Block from './Block';
import Score from './Score';
import Text from '../../basic/Text/Text';
import Button from '../../basic/Button/Button';
import Popup from '../../basic/Popup/Popup';

import React from 'react';
import { useState } from 'react';
import { LanguageContext } from '../../../contexts/languageContext';

function Game() {
  const info = React.useContext(LanguageContext).fancy.game;
  const popup = React.useContext(LanguageContext).service;
  let arr = [[],[],[],[],[],[],[],[],[],[]];
  const [game, setGame] = useState(false);
  const [score, setScore] = useState(0);
  const [move, setMove] = useState(0);
  const [table, setTable] = useState(getTable());
  const [topMoves, setTopMoves] = useState(localStorage.getItem('moves') || 0);
  const [topScore, setTopScore] = useState(localStorage.getItem('top') || 0);
  let turn = 0;

  (!localStorage.getItem('moves')) && localStorage.setItem('moves','0');
  (!localStorage.getItem('top')) && localStorage.setItem('top','0');

  const [popupSureOpen, setPopupSureOpen] = useState (false);
  const [popupSureAction, setPopupSureAction] = useState ('');
  function closePopup() {
    setPopupSureOpen(false);
  }

  function openSurePopup(action) {
    setPopupSureAction(action);
    setPopupSureOpen(true);
  }

  function popupAction (action) {
    (action ==='refresh') && refresh();
    (action ==='restart') && restart();
    closePopup();
  }

  function restart() {
    setTable(getTable());
    setGame(false);
    setMove(0);
    setScore(0);
    turn = 0;
  }

  function refresh() {
    localStorage.setItem('moves','0');
    setTopMoves(0);
    localStorage.setItem('top','0');
    setTopScore(0);
  }


  function getTable() {
  const tab = [];
  for (let i=0; i < 10; i++) {
    tab[i] = [];
    for (let j=0; j < 10; j++) {
      tab[i][j] = {};
      tab[i][j].mark = false;
      tab[i][j].col = Math.floor(Math.random()*6);
    }
  }
  return tab;
  }

  function mark(i,j) {
    let tab = table.map((item) => {return item});
    tab[i][j].mark = true;
    return tab;
  }

  function gameOver() {
    if (move < 119) {
    for (let m=0; m < 10; m++) {
      for (let n=0; n < 10; n++) {
        if (table[0][0].col !== table[n][m].col) {return false;}
      }
    }
    };
      let sc = localStorage.getItem('top');
      console.log(sc);
      if (sc && (sc < (score + (move - turn) + 1))) {
        localStorage.setItem('moves', move + 1);
        setTopMoves(move + 1);
        localStorage.setItem('top', score + (move - turn) + 1);
        setTopScore(score + (move - turn) + 1);
        console.log(localStorage.getItem('top'));
      }
    return true;
  }

  function toggle (el) {
    if (el.mark) {
      (el.col = (el.col +1) % 6)
      turn++;
      setScore(score + (move - turn) + 1)
    };
    el.mark = false;
    return el;
  }

  function check (i, j, k, l) {
    let ch = false;
    if ((arr[i][j]) && table && (table[i][j].col === table[k][l].col)) {
      setTable(mark(k,l));
      arr[k][l] = true;
      ch = true;
    }
    return ch;
  }

  function checkAround(i, j) {
    let ch = [];
    (i > 0) && (ch[1] = check(i, j, i-1, j));
    (i < 9) && (ch[2] = check(i, j, i+1, j));
    (j > 0) && (ch[3] = check(i, j, i, j-1));
    (j < 9) && (ch[4] = check(i, j, i, j+1));
    (ch[0] = check(i, j, i, j));
    arr[i][j] = false;
  };

  function checkAll(i,j) {
    !game && setMove(move + 1);
      arr[i][j] = true;
      checkAround(i,j);
      for (let m=0; m<17; m++) {
      table.forEach((row,k) => {
        row.forEach((el,l) => {
          if(table[k][l].mark && arr[k][l]) {
            checkAround(k,l);
          }
        })
    })}
    let tab = table.map((item) => {return item.map((el) => {
      return toggle(el);
    })});
    setGame(gameOver());
    if (game) {

    }
    setTable(tab); 
  }

  function display() {
    let disp = []; 
    table.forEach((row,i) => {
      row.forEach((el,j) => {
        disp.push(<Block key={`${i}-${j}`} i={i} j={j} col={table[i][j].col} toggle={checkAll} game={game}/>)
      })
    })
  return disp;
  }

  return (
    <div className="game">
      <Score title={info.myscore} action="restart" moves={move} points={score} onClick={openSurePopup} button={info.restart} />
      <div className={game ? "game__board game__board_blocked" : "game__board"}>
        {display()}
        {game && <div className="game__over">
          <Text caption={info.gameover} text={[`${info.yourscore}`, `${move} ${info.mvs}: ${score} ${info.pts}`]}/>
          <Button text={info.again} onClick={restart} />
        </div>}
      </div>
      <Score title={info.topscore} action="refresh" moves={topMoves} points={topScore} onClick={openSurePopup} button={info.refresh} />
      <Popup key='sure' caption={popup.sure} isOpen={popupSureOpen} onClose={closePopup}>
          <Button text={popup.yes} onClick={popupAction} action={popupSureAction}/>
          <Button text={popup.no} onClick={closePopup}/>          
      </Popup>
    </div>
  );

}

export default Game;