import Button from "../../basic/Button/Button";
import Text from "../../basic/Text/Text";

function Score(props) {
  function onClick() {
    props.onClick(props.action)
  }
  return (
    <div className="game__score">
      <Text caption={props.title} text={`${props.moves} mvs: ${props.points} pts`} />
      <Button text={props.button} onClick={onClick} />
    </div>
  );
}

export default Score;