import './Button.css';
import React from 'react';


function Button(props) {
  function click() {
    props.action ? props.onClick(props.action) : (props.onClick && props.onClick());
  }
  return (
    props.anchor ?
      (props.onClick ? <a className={'button'} href={`#${props.id}`}><button className='button__ghost' onClick={click}>{props.text}</button></a> : <a className={'button'} href={`#${props.id}`}>{props.text}</a>)
    :
      <button className={`button button_type_${props.style}`} type="button" onClick={click} disabled={props.disabled}>{props.text}</button>
  );
}

export default Button;