import React from "react";
//import { useState, useEffect } from "react";
import './App.css';
import Main from '../Main/Main';
import Background from "../../fancy/Background/Background";
import { LanguageContext, languages } from '../../../contexts/languageContext';

function App() {
/*  
useEffect(() => {
  navigator.languages && setRussian((navigator.languages.some((lang) => { return lang.includes('ru'); })) || navigator.language.includes('ru'));

}, []);

useEffect(() => {
  if (russian) {
    setTexts(textsRu);
    setService(controls.ru);
    setExtra(extraRu);
    document.title = controls.ru.pageTitle;
  } else {
    setTexts(textsEn);
    setService(controls.en);
    setExtra(extraEn);
    document.title = controls.en.pageTitle;
  }
},[russian]); 
*/
  return (
    <LanguageContext.Provider value={languages['en']}>
      <div className='app'>
        <Main />
        <Background main={48}/>
      </div>
    </LanguageContext.Provider>
  );
}

export default App;
