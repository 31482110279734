import './Header.css';
import Navigation from '../Navigation/Navigation';
import Text from '../Text/Text';
import Background from '../../fancy/Background/Background';
import Social from '../Social/Social';
import socials from '../../../constants/socials';
import React from 'react';
import photo from '../../../images/Museumme2.jpg';
//import photo2 from '../../../images/Octokuss.jpg';
import { LanguageContext } from '../../../contexts/languageContext';

function Header(props) {
  const links = [];
  socials.forEach((el,i) => {
    links.push(<Social key={i} text={el.text} picture={el.pics[0]} link={el.link}/>)
  });
  const info = React.useContext(LanguageContext).header;
  return (
    <header className='header' id='top'>
      <div className='header__me'>
        <img className='header__photo' src={photo} alt='The Other' />
        <div className='header__socials'>
          {links}
        </div>
      </div>
      <Text key='0' caption={info.info[0].caption} text={info.info[0].text} link={info.info[0].link} alterlink={info.info[0].alterlink} />
      <Text key='1' caption={info.info[1].caption} text={info.info[1].text} link={info.info[1].link} alterlink={info.info[1].alterlink} />
      <Text key='2' caption={info.info[2].caption} text={info.info[2].text} link={info.info[2].link} alterlink={info.info[2].alterlink} />
      <Navigation onClick={props.onClick} />
      <Background />
    </header>
  );
}

export default Header;