import in0 from "../images/socials/in0.png";
import in1 from "../images/socials/in1.png";
import gh0 from "../images/socials/gh0.png";
import gh1 from "../images/socials/gh1.png";
import fb0 from "../images/socials/fb0.png";
import fb1 from "../images/socials/fb1.png";
import wa0 from "../images/socials/wa0.png";
import wa1 from "../images/socials/wa1.png";
import ig0 from "../images/socials/ig0.png";
import ig1 from "../images/socials/ig1.png";
import mt0 from "../images/socials/mt0.png";
import mt1 from "../images/socials/mt1.png";

const socials = [
  {
    text: 'LinkedIn',
    link: 'https://www.linkedin.com/in/torshenko/',
    pics: [in0, in1]
  },
  {
    text: 'GitHub',
    link: 'https://github.com/ulkerei',
    pics: [gh0, gh1]
  },
  {
    text: 'FaceBook',
    link: 'https://www.facebook.com/julie.torche.5',
    pics: [fb0, fb1]
  },
 /*{
    text: 'WhatsApp',
    link: '',
    pics: [wa0, wa1]
  },
  {
    text: 'Instagram',
    link: 'https://insragram.com/ulkerei',
    pics: [ig0, ig1]
  },*/
  {
    text: 'Send e-mail',
    link: 'mailto:ulka.torshenko@gmail.com',
    pics: [mt0, mt1]
  },
];

export default socials;