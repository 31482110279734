import './Footer.css';
import React from 'react';
import Text from '../Text/Text';
import { LanguageContext } from '../../../contexts/languageContext';

function Footer(props) {
  const info = React.useContext(LanguageContext).footer;
  return (
    <footer className='footer'>
      <Text text={info.text} link={info.link} alterlink={info.alterlink}/>
    </footer>
  );
}

export default Footer;