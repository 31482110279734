import './Portfolio.css';
import React from 'react';
import Card from '../Card/Card';
import { LanguageContext } from '../../../contexts/languageContext';

function Portfolio() {
  const info = React.useContext(LanguageContext).projects;
  const projects = [];
  info.forEach((block, i) => {
    projects.push(<Card 
      key={i}
      caption={block.caption}
      pictures={block.pictures}
      text={block.text}
      long={block.long}
      techs={block.techs}
      gitlink={block.gitlink}
      mainlink={block.mainlink}
      link={block.link}
      alterlink={block.alterlink}
    />);
  })
  return (
    <section className='portfolio'>
      <nav className='portfolio__nav'>
        {projects}
      </nav>
    </section>
  );
}

export default Portfolio;