import './Navigation.css';
import React from 'react';
import Button from '../Button/Button';
import { LanguageContext } from '../../../contexts/languageContext';

function Navigation(props) {
  const sections = React.useContext(LanguageContext).sections;
  const display =[];
  sections.forEach((sec,i) => {
    sec.id && display.push(<Button 
      key={sec.id}
      id={sec.id}
      text={sec.name}
      anchor={true}
      onClick={props.onClick}
      action={i}
    />);
  })
  return (
    <div className='navigation'>
      {display}
    </div>
  );
}

export default Navigation;
