import './Card.css';
import Text from '../Text/Text';
import Button from '../Button/Button';
import React from 'react';
import { useState } from 'react';
import { LanguageContext } from '../../../contexts/languageContext';

function Card(props) {
  const card = React.useContext(LanguageContext).service.card;
  const [display, setDisplay] = useState(0);
  const [more, setMore] = useState(false);
  function toggle() {
    setDisplay((display + 1) % props.pictures.length);
  }

  function info() {
    setMore(!more);
  }

  return (
    <div className="card">
      {props.pictures[display] && <img className='card__pic' src={props.pictures[display]} alt={props.caption} onClick={toggle}/>}
      <div className='card__heading'>
        <Button text={more ? '⊼' : '?'} onClick={info}/>
        <Text caption={props.caption}/>
        <a className='card__link' target='_blank' rel='noreferrer' href={props.mainlink}>
          {props.mainlink ? <Button text='↗' /> : <Button text='↗' disabled/>}
        </a>
      </div>
      {more ? <div className='card__more'>
        <Text text={props.text} />
        <Text text={props.long} link={props.link} alterlink={props.alterlink}/>
        {props.techs && <Text caption={card.techs} text={props.techs} techs={true} />}
        <Text link={props.gitlink} alterlink={card.git} />
      </div>
      : <Text text={props.text} link={props.gitlink} alterlink={card.git} />}
      <div className='card__rest' />
    </div>
  );
}

export default Card;