import './Section.css';
import Text from '../Text/Text';
import Button from '../Button/Button';
import Portfolio from '../Portfolio/Portfolio';
import Background from '../../fancy/Background/Background';
import Game from '../../fancy/Game/Game';
import React from 'react';
import { LanguageContext } from '../../../contexts/languageContext';
import { useState } from 'react';

function Section(props) {
  const game = React.useContext(LanguageContext).fancy.game;
  const titleoff = React.useContext(LanguageContext).service.titleoff;
  const [isOpen, setIsOpen] = useState(!props.hide);

  function toggle() {
    setIsOpen(!isOpen)
  }

  const info =[];
  props.info.forEach((block, i) => {
    info.push(<Text 
      key={i}
      caption={block.caption}
      text={block.text}
      techs={block.techs}
      link={block.link}
      alterlink={block.alterlink}
    />);
  })
  
  return (
    <section className="section" id={props.id}>
      <div className='section__heading'>
        <Button text={isOpen ? '━' : '▽'} onClick={toggle} />
        {(props.id === "game") ? 
          <h2 className='section__caption' onClick={toggle}>{isOpen ? `${game.titleon}` : `${game.titleoff}`}</h2>
        :
          <h2 className='section__caption' onClick={toggle}>{props.name}</h2>
//        <h2 className='section__caption' onClick={toggle}>{isOpen ? `${props.name}` : `${titleoff} "${props.name}"`}</h2>
        }
        <Button text='⇪' anchor={true} id='top'/>
        <Background />
      </div>
      <div className={isOpen ? 'section__block' : 'section__block section__block_hide'}>
        {(props.id === "game") && <Text> 
          <p className='text__text text__intro'>{game.intro}
          {game.span.map((tx, i) => {return <span key={i} className='text__text  text__intro text__span'>{`${tx} > `}</span>})}
          {<span key='last' className='text__text text__intro text__span'>{game.span[0]}</span>}
          </p>
          </Text>}
        {info}
        {(props.id === "portfolio") && <Portfolio />}
        {(props.id === "game") && <Game />}
      </div>

    </section>
  );
}

export default Section;
