import './Main.css';
import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Section from '../Section/Section';
import { LanguageContext } from '../../../contexts/languageContext';

function Main(props) {
  const sections = React.useContext(LanguageContext).sections;
  const display = [];
  sections.forEach((sec,i) => {
    sec.id && display.push(<Section 
      key={sec.id}
      id={sec.id}
      name={sec.name}
      info={sec.info}
      hide={sec.hide}
    />);
  })

  function onNavClick(i=0) {
    const sec = document.querySelectorAll('.section');
    const block = sec[i].querySelector('.section__block');
    const button = sec[i].querySelector('button');
    block.classList.contains('section__block_hide') && button.click();
  }

  return (
    <main className='main'>
      <Header onClick={onNavClick}/>
      {display}
      <Footer />
    </main>
  );
}

export default Main;