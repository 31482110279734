function Block(props) {
  const i = props.i;
  const j = props.j;

  function toggle () {
    props.toggle(i, j);
  }
  
  return (
    <button key={`${i}-${j}`} i={i} j={j} className={`game__block game__block_${props.col}`} onClick={toggle} disabled={props.game}/>
  );
}

export default Block;