import './Text.css';

function Text(props) {
  const text = props.text;
  function setText () {
    if (Array.isArray(text)) {
      const texts = [];
      text.forEach((p, i) => {texts.push(props.techs ? <p className='text__tech' key={i}>{p}</p> : <p className='text__text' key={i}>{p}</p>)});
      return texts;
    } else {return <p className='text__text'>{text}</p>}
  }
  return (
    <div className={text || props.link || props.mainlink ? "text" : "text text_nopad"}>
      {props.caption && <h3 className='text__caption'>{props.caption}</h3>}
      {text && (props.techs ? <div className='text__techs'>{setText()}</div>: setText())}
      {props.link && <a className='text__link' href={props.link} target='_blank' rel="noreferrer">{props.alterlink || props.link}</a>}
      {props.children}
    </div>
  );
}

export default Text;
