import './Popup.css';
import Button from '../Button/Button'
import Text from '../Text/Text';
import React from 'react';

function Popup(props) {
  return (
    <div className={props.isOpen ? "popup" : "popup popup_hide"}>
      <div className='popup__overlay' />
      <div className='popup__body'>
        <div className='popup__heading'>
          <h3 className='popup__caption'>{props.caption}</h3>
          <Button text='X' onClick={props.onClose} />
        </div>
        <div className='popup__inner'>
          {(props.text || props.link) && <Text
          text={props.text}
          link={props.link}
          alterlink={props.alterlink} />}
          <div className='popup__buttons'>
            {props.children}    
          </div>
        </div>
      </div>  
    </div>
  );
}

export default Popup;